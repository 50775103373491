export class Sesion {
    usuario: string;
    conductor:string;
    hash: string;
    idTipoDeServicio: string;
    id: string;
    semilla: string;
    estado: string;
}

