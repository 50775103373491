export class Alertas {
    id: string;
    de: string;
    para: string;
    titulo: string;
    cuerpo: string;
    importancia: string;
    estado: string;
    fechaAlta: string;
}
